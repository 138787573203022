import React, {useState } from 'react'
import Seo from '../components/Seo';
import Layout from '../components/Layout';

const Contacto = () => {

    const [nombre, setNombre] = useState("")
    const [email, setEmail] = useState("")
    const [mensaje, setMensaje] = useState("")
    const [alerta, setAlerta] = useState([])

    
   

    const ValidateEmail = email => {
        const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (email.match(emailformat)) {
            return true;
        }
        
        return false
    };

    const handleSubmit = e => {

        e.preventDefault()

        if([ nombre, email, mensaje ].includes("")) {
            setAlerta(["Todos los campos son obligatorios", "bg-red-500"])
            return
        }
        if(!ValidateEmail(email)) {
            setAlerta(["Ingrese un email correcto", "bg-red-500"])
            return
        }

        setAlerta([]);

        const formulario = document.querySelector("#formulario_contacto");

        const formData = new FormData(formulario);

          fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString()
          })
            .then(() => {
              
              setAlerta(["El mensaje se envio correctamente", "bg-green-500"])
            })
            .catch(err => {
              console.log(err)
              setAlerta(["Ocurrio un error, por favor intente más tarde", "bg-red-500"])
                
            });
        

    }

  return (
    <Layout>
      <Seo title={"Digital System DA - Contacto"}/>
      <section className="w-11/12 mx-auto mt-24 md:mb-10 md:flex justify-between items-center">
        <div className='w-full md:w-1/2 contacto_text'>
          <p className="text-primary mb-4 text-center md:text-start">CONTACTO</p>

          <h3 className="font-bold text-3xl md:text-5xl ">Ponte en contacto con nuestro equipo</h3>
          <p className="text-tiny-text mt-10 text-lg md:text-xl">Comunicate con nosotros a través del formulario o utilizando los diferentes puntos de contacto. Nos comunicaremos contigo lo más rápido posible.</p>
          <div className='md:flex justify-between mt-10'>
            <div className='w-6/12 md:w-5/12'>
                <p className='font-bold text-lg text-secondary md:mb-12'>Puntos de contacto</p>
                <p className="text-tiny-text transition-all pt-3 block">hola@digitalsystemda.com.ar</p>
                <p className="text-tiny-text transition-all pt-3 block">+54 9 11 53119950</p>  

            </div>
            <div className='w-6/12 md:w-5/12'>
                <p className='font-bold text-secondary md:mb-12 mt-4 md:mt-0'>Redes sociales</p>
                <a href="https://www.linkedin.com/company/digital-system-da/" target="_blank" rel="noopener noreferrer" className="text-gray-500 transition-all hover:text-secondary pt-3 block">Linkedin</a>
                <a href="https://www.instagram.com/digitalsystemda/" target="_blank" rel="noopener noreferrer" className="text-gray-500 transition-all hover:text-secondary pt-3 block">Instagram</a>

            </div>
          </div>
        </div>
        <form onSubmit={e => handleSubmit(e)} name="contacto" method='post' id="formulario_contacto" className='w-full md:w-5/12 block mt-10' data-netlify-honeypot="bot-field" data-netlify="true">

          <input type="hidden" name="form-name" value="contacto" />
          
          <div className='relative mb-6'>
            <label htmlFor="nombre" className='absolute text-secondary block -top-3 left-4 text-sm bg-white rounded-3xl px-1'>Nombre</label>
            <input className="border outline-none rounded-3xl py-2 px-4 border-secondary block w-full" placeholder='Ingrese nombre' name='nombre' id='nombre' type="text" value={nombre} onChange={e => setNombre(e.target.value)} />

          </div>
          
          <div className='relative mb-6'>
            <label className='absolute text-secondary block -top-3 left-4 text-sm bg-white rounded-3xl px-1' htmlFor="email" >Email</label>
            <input className="border outline-none rounded-3xl py-2 px-4 border-secondary block w-full" name='email' id='email' type="email" placeholder='Ingrese email' value={email} onChange={e => setEmail(e.target.value)} />

          </div>

          <div className='relative mb-6'>
            <label className='absolute text-secondary block -top-3 left-4 text-sm bg-white rounded-3xl px-1' htmlFor="mensaje">Mensaje</label>
            <textarea className="border outline-none rounded-3xl py-2 px-4 border-secondary block w-full h-32 max-h-32" name='mensaje' placeholder='Ingrese mensaje' id="mensaje" value={mensaje} onChange={e => setMensaje(e.target.value)} ></textarea>

          </div>

          { alerta.length > 0 && <p className={`text-center mb-5 py-2 rounded-sm text-third ${alerta[1]}`}>{alerta[0]}</p> }

          
          <input type="submit" className='bg-primary outline-none mx-auto cursor-pointer text-secondary rounded-3xl py-2 px-5 block transition-colors hover:bg-btn1-hover' value="Enviar" />
                
        </form>
        

      </section>
    </Layout>
  )
}

export default Contacto;